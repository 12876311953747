import { __awaiter, __generator } from "tslib";
export default function componentLoader(componentImport) {
    return __awaiter(this, void 0, void 0, function () {
        var pageHasAlreadyBeenForceRefreshed, component, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem('page-has-been-force-refreshed') || 'false');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, componentImport()];
                case 2:
                    component = _a.sent();
                    window.localStorage.setItem('page-has-been-force-refreshed', 'false');
                    return [2 /*return*/, component];
                case 3:
                    error_1 = _a.sent();
                    if (!pageHasAlreadyBeenForceRefreshed) {
                        // Assuming that the user is not on the latest version of the application.
                        // Let's refresh the page immediately.
                        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
                        return [2 /*return*/, window.location.reload()];
                    }
                    // The page has already been reloaded
                    // Assuming that user is already using the latest version of the application.
                    // Let's let the application crash and raise the error.
                    throw error_1;
                case 4: return [2 /*return*/];
            }
        });
    });
}
